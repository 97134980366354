import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57cadb82"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "install"
}
const _hoisted_2 = { class: "item-input-wrapper" }
const _hoisted_3 = { href: "https://hubs.la/Q02XvGwv0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (!_ctx.loader || !_ctx.session || !_ctx.code || !_ctx.shop)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_list, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Logo),
                    (_ctx.authenticationInProgress)
                      ? (_openBlock(), _createBlock(_component_ion_item, {
                          key: 0,
                          lines: "none"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "start",
                              icon: _ctx.cloudDownloadOutline
                            }, null, 8, ["icon"]),
                            _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('Installing HotWax Commerce onto your Shopify store...')) + " ", 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.$t("You'll be auto redirected to your Shopify store to complete the installation process")), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : (_ctx.syncDetailsToShopify || _ctx.isAppInstalled)
                        ? (_openBlock(), _createBlock(_component_ion_item, {
                            key: 1,
                            lines: "none"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                slot: "start",
                                color: "success",
                                icon: _ctx.checkmarkCircleOutline
                              }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('HotWax Commerce is installed on your Shopify store')) + " ", 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.shop), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                    (_ctx.authenticationInProgress)
                      ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
                          key: 2,
                          type: "indeterminate"
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.syncDetailsToShopify || _ctx.isAppInstalled)
                      ? (_openBlock(), _createBlock(_component_ion_item, {
                          key: 3,
                          lines: "none",
                          disabled: _ctx.authenticationInProgress
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "start",
                              icon: _ctx.cloudUploadOutline
                            }, null, 8, ["icon"]),
                            (_ctx.syncDetailsToShopify)
                              ? (_openBlock(), _createBlock(_component_ion_label, {
                                  key: 0,
                                  class: "ion-text-wrap"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('Syncing Shopify store to HotWax Commerce')), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (_ctx.isAppInstalled)
                              ? (_openBlock(), _createBlock(_component_ion_label, {
                                  key: 1,
                                  class: "ion-text-wrap"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('Shopify store synced with HotWax Commerce')), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["disabled"]))
                      : _createCommentVNode("", true),
                    (_ctx.syncDetailsToShopify)
                      ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
                          key: 4,
                          type: "indeterminate"
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isAppInstalled)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                          _createVNode(_component_ion_item, { lines: "none" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_input, {
                                label: _ctx.$t('OMS'),
                                modelValue: _ctx.instanceAddress,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.instanceAddress) = $event)),
                                placeholder: _ctx.$t('notnaked-oms'),
                                "helper-text": "https://notnaked-oms.hotwax.io",
                                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isConfigUpdated = false))
                              }, null, 8, ["label", "modelValue", "placeholder"])
                            ]),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_2, [
                            _createVNode(_component_ion_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_input, {
                                  label: _ctx.$t('JWT Token'),
                                  placeholder: _ctx.$t('notnaked-oms'),
                                  type: "password",
                                  modelValue: _ctx.instanceToken,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.instanceToken) = $event)),
                                  onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isConfigUpdated = false))
                                }, null, 8, ["label", "placeholder", "modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_note, {
                              class: "ion-margin-start",
                              color: "primary",
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.generateTokenDocLink()))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("Generate a JWT Token")), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _createVNode(_component_ion_button, {
                            class: "ion-margin-vertical",
                            disabled: !_ctx.instanceAddress || !_ctx.instanceToken,
                            expand: "block",
                            onClick: _ctx.updateConnectConfig,
                            color: "dark"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("Finish Setup")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_icon, {
                                slot: "end",
                                icon: _ctx.arrowForwardOutline
                              }, null, 8, ["icon"])
                            ]),
                            _: 1
                          }, 8, ["disabled", "onClick"]),
                          _createVNode(_component_ion_item, {
                            button: "",
                            detail: "",
                            lines: "none",
                            onClick: _ctx.openOmsModal
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("Find your OMS name")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["onClick"]),
                          _createVNode(_component_ion_item, {
                            detail: "",
                            lines: "none"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, [
                                _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("Don't have a HotWax Commerce account?")), 1)
                                  ]),
                                  _: 1
                                }),
                                _createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.$t("Contact Us")), 1)
                              ])
                            ]),
                            _: 1
                          })
                        ], 64))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ion_item, {
                      class: "ion-item-button ion-margin-top",
                      lines: "none",
                      button: "",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.goToLearnMoreDocument()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          slot: "start",
                          icon: _ctx.bookOutline
                        }, null, 8, ["icon"]),
                        _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('Learn more about connecting HotWax Commerce with Shopify')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}